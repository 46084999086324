import React from 'react';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

// Hardcoded switch to disable count badges until further notice
const ITEM_COUNT_ACTIVE = false;

/**
 * Items count icon component
 * @author Andrzej Wieclaw, Afry
 */
const ItemCountIcon = ({ count, icon, color }) =>
  count === 0 || !ITEM_COUNT_ACTIVE ? (
    icon
  ) : (
    <Badge badgeContent={count < 0 ? '?' : count} color={color} className="itemCountIconBadge">
      {icon}
    </Badge>
  );

ItemCountIcon.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string,
};

ItemCountIcon.defaultProps = {
  count: 0,
  color: 'error',
};

export default ItemCountIcon;
