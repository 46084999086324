import React from 'react';
import PropTypes from 'prop-types';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import { Typography, Card, CardContent, Button } from '@material-ui/core';

/**
 * Person details component
 * @author Bartosz Marchewka, Trivalo
 */
const Person = ({ person }) => (
  <div>
    <Card variant="outlined">
      <CardContent className="contactCardContent">
        <Typography variant="h6">{person.displayName}</Typography>
        <Typography className="contactCardPersonTitle" variant="subtitle1">
          {person.title}
        </Typography>
        <Typography variant="subtitle2">
          {person.email !== '' && (
            <Button
              data-testid="emailBt"
              color="inherit"
              size="small"
              startIcon={<MailOutlineIcon color="secondary" />}
              onClick={() => {
                window.location.href = `mailto:${person.email}`;
              }}
              className="contactButtonLight"
            >
              {person.email}
            </Button>
          )}
          {person.phone !== '' && (
            <Button
              data-testid="phoneBt"
              color="inherit"
              size="medium"
              startIcon={<PhoneIcon color="secondary" />}
              onClick={() => {
                window.location.href = `tel:${person.phone}`;
              }}
              className="contactButtonLight"
            >
              {person.phone}
            </Button>
          )}
        </Typography>
      </CardContent>
    </Card>
  </div>
);

Person.propTypes = {
  person: PropTypes.shape({
    personId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default Person;
