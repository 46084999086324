const baseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api`;

/**
 * Convert error status code from backend to
 * error message key that will be translated to UI message
 * @param {int} statusCode
 * @returns errorMsgKey
 * @author Bartosz Marchewka, Trivalo
 */
const getErrorCodeKey = (statusCode) => (statusCode === 404 ? 'errorDataNotFound' : 'errorGeneral');

/**
 * REST Call API method
 * @param url
 * @returns result
 * @author Bartosz Marchewka, Trivalo
 */
const getRestCall = async (url) => {
  let result = {};
  try {
    const response = await fetch(url);
    const resultData = await response.json();

    if (response.status >= 400) {
      result = {
        error: getErrorCodeKey(response.status),
      };
    } else {
      result = {
        data: resultData,
      };
    }
  } catch (error) {
    result = {
      error: getErrorCodeKey(0),
    };
  }
  return result;
};

const postRestCall = async (url, data) => {
  const formData = new FormData();
  const { ticket, attachments } = data;
  formData.append(
    'ticket',
    new Blob([JSON.stringify(ticket)], {
      type: 'application/json',
    })
  );
  if (attachments) {
    attachments.forEach((att) => formData.append('attachments', att.file, att.file.name));
  }
  let result = {};
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const resultData = await response.json();
    if (response.status >= 400) {
      result = {
        error: getErrorCodeKey(response.status),
      };
    } else {
      result = {
        data: resultData,
      };
    }
  } catch (error) {
    result = {
      error: getErrorCodeKey(0),
    };
  }
  return result;
};

/**
 * Backend client
 * @author Bartosz Marchewka, Trivalo
 */
const BackendClient = {
  /**
   * Call API to get attachments config
   */
  getAttachmentsConfig: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/config/attachments`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call API to take information about the asset
   * @param {string} uid
   * @returns asset
   */
  getAsset: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/asset`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call API to take information about the location
   * @param {string} uid
   * @returns location
   */
  getLocation: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/location`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to take contacts informations
   * @param {string} uid
   * @returns persons
   */
  getContacts: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/contacts`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to take information about
   * ongoing work
   * @param {string} uid
   * @returns works
   */
  getOngoingWork: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/work/ongoing`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to take information about
   * historical work
   * @param {string} uid
   * @returns works
   */
  getHistoricalWork: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/work/historical`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to take ticket priorities
   * @param {string} uid
   * @returns priorities
   */
  getTicketPriorities: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/priorities`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to take work types for ticket
   * @param {string} uid
   * @returns worktypes
   */
  getTicketWorkTypes: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/worktypes`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to fetch problem codes for ticket
   * @param {string} uid
   * @returns problem codes
   */
  getTicketProblemCodes: async (uid) => {
    const callUrl = `${baseUrl}/${uid}/problemcodes`;
    const result = await getRestCall(callUrl);
    return result;
  },

  /**
   * Call backend to store information about new service request
   * @param {string} uid
   * @param {Array} data
   * @returns ticket
   */
  saveTicket: async (uid, data) => {
    const callUrl = `${baseUrl}/${uid}/ticket`;
    const result = await postRestCall(callUrl, data);
    return result;
  },
};

export default BackendClient;
