import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import HistoryIcon from '@material-ui/icons/History';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { useTranslation } from 'react-i18next';
import ItemCountIcon from './ItemCountIcon';

/**
 * Additional button component to help
 * with navigation in application
 * @author Bartosz Marchewka, Trivalo
 */
const ButtonSection = ({ contactsCount, ongoingWorkCount, historicalWorkCount }) => {
  const { t } = useTranslation();
  const { uid, appScope } = useParams();

  return (
    <div className="buttonSection">
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        startIcon={<BuildIcon />}
        component={Link}
        to={`/${appScope}/${uid}/sr`}
      >
        {t('createServiceRequest')}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        startIcon={<ItemCountIcon count={ongoingWorkCount} icon={<BusinessCenterIcon />} />}
        component={Link}
        disabled={!ongoingWorkCount}
        className={ongoingWorkCount === undefined ? 'buttonLoadingIcon' : ''}
        to={`/${appScope}/${uid}/work/ongoing`}
      >
        {t('ongoingWork')}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        startIcon={<HistoryIcon />}
        component={Link}
        disabled={!historicalWorkCount}
        className={historicalWorkCount === undefined ? 'buttonLoadingIcon' : ''}
        to={`/${appScope}/${uid}/work/historical`}
      >
        {t('historicalWork')}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        startIcon={<PeopleIcon />}
        component={Link}
        disabled={!contactsCount}
        className={contactsCount === undefined ? 'buttonLoadingIcon' : ''}
        to={`/${appScope}/${uid}/contact`}
      >
        {t('contactPersons')}
      </Button>
    </div>
  );
};

ButtonSection.propTypes = {
  contactsCount: PropTypes.number,
  ongoingWorkCount: PropTypes.number,
  historicalWorkCount: PropTypes.number,
};

ButtonSection.defaultProps = {
  contactsCount: undefined,
  ongoingWorkCount: undefined,
  historicalWorkCount: undefined,
};

export default ButtonSection;
