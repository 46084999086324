import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import AssetLoc from './components/assetloc/AssetLoc';
import About from './components/About';
import ServiceRequest from './components/serviceRequest/ServiceRequest';
import PersonList from './components/person/PersonList';
import theme from './theme';
import Message from './components/Message';
import OngoingWorkList from './components/work/OngoingWorkList';
import HistoricalWorkList from './components/work/HistoricalWorkList';

/**
 * Main APP component
 * @author Bartosz Marchewka, Trivalo
 */
function App() {
  // UID value in App.jsx is required to properly
  // build Header component (options in burger menu)
  const [uid, setUid] = useState();
  const [appScope, setAppScope] = useState();
  const [contactsCount, setContactsCount] = useState();
  const [ongoingWorkCount, setOngoingWorkCount] = useState();
  const [historicalWorkCount, setHistoricalWorkCount] = useState();
  const allAppScopeRegex = '(asset|location)';

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Header
          uid={uid}
          appScope={appScope}
          contactsCount={contactsCount}
          ongoingWorkCount={ongoingWorkCount}
          historicalWorkCount={historicalWorkCount}
        />
        <main>
          <Switch>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path={`/:appScope${allAppScopeRegex}/:uid/sr`}>
              <ServiceRequest setUid={setUid} setAppScope={setAppScope} />
            </Route>
            <Route exact path={`/:appScope${allAppScopeRegex}/:uid/contact`}>
              <PersonList setUid={setUid} setAppScope={setAppScope} />
            </Route>
            <Route exact path={`/:appScope${allAppScopeRegex}/:uid/work/ongoing`}>
              <OngoingWorkList setUid={setUid} setAppScope={setAppScope} />
            </Route>
            <Route exact path={`/:appScope${allAppScopeRegex}/:uid/work/historical`}>
              <HistoricalWorkList setUid={setUid} setAppScope={setAppScope} />
            </Route>
            <Route exact path={`/:appScope${allAppScopeRegex}/:uid`}>
              <AssetLoc
                setUid={setUid}
                setAppScope={setAppScope}
                setContactsCount={setContactsCount}
                contactsCount={contactsCount}
                setOngoingWorkCount={setOngoingWorkCount}
                ongoingWorkCount={ongoingWorkCount}
                setHistoricalWorkCount={setHistoricalWorkCount}
                historicalWorkCount={historicalWorkCount}
              />
            </Route>
            <Route>
              <Message type="error" messageKey="errorInvalidRequest" />
            </Route>
          </Switch>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
