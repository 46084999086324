import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import BackendClient from '../../services/BackendClient';
import WorkList from './WorkList';

/**
 * Historical work component - entry point to get
 * work list data from backend
 * @author Andrzej Wieclaw, Afry
 */
const HistoricalWorkList = ({ setUid, setAppScope }) => {
  const { uid, appScope } = useParams();

  const fetchWorkData = useCallback(async () => BackendClient.getHistoricalWork(uid), [uid]);

  useEffect(() => {
    /**
     * Set uid value that comes from weburl parameter
     * into the uid state that is defined in App.jsx
     */
    setUid(uid);
    setAppScope(appScope);
  }, [setUid, uid, setAppScope, appScope]);

  return <WorkList fetchWorkData={fetchWorkData} />;
};

HistoricalWorkList.propTypes = {
  setUid: PropTypes.func.isRequired,
  setAppScope: PropTypes.func.isRequired,
};
export default HistoricalWorkList;
