import React from 'react';
import Message from './Message';

/**
 * About component
 * @author Bartosz Marchewka, Trivalo
 */
const About = () => <Message type="error" messageKey="errorInvalidRequest" />;

export default About;
