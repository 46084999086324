import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  TextField,
  Button,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Prompt, useHistory } from 'react-router-dom';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BuildIcon from '@material-ui/icons/Build';

const MAX_FILE_NAME_LENGTH = 25;

const getFileSize = (number) => {
  let result = null;
  if (number < 1024) {
    result = `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    result = `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    result = `${(number / 1048576).toFixed(1)} MB`;
  }
  return result;
};

/**
 * Service request form component
 * @author Bartosz Marchewka, Trivalo
 */
const ServiceRequestForm = ({
  attachmentsConfig,
  priorities,
  workTypes,
  problemCodes,
  sendTicket,
  displayForm,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [leaveFormConfirmed, setLeaveFormConfirmed] = useState(false);
  const [nextLocation, setNextLocation] = useState();
  const inputFiles = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [validAttachmentsCount, setValidAttachmentsCount] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    /** Initialize form fields with default values.
     * to prevent react strict warning:
     * "A component is changing an uncontrolled input to be controlled"
     */
    defaultValues: {
      summary: '',
      details: '',
      customerRef: '',
      priority: '',
      workType: '',
      problemCode: '',
    },
  });

  const { isDirty, dirtyFields } = useFormState({
    control,
  });

  // Function that is use for Promp component.
  // It must return true or false. This function is also responsible
  // for showing custom dialog. Custom dialog is better solution than standard
  // web browser prompt
  const confirmFormLeaving = (location) => {
    setNextLocation(location);
    if (!leaveFormConfirmed) {
      setDialogVisible(true);
      return false;
    }
    return true;
  };

  /**
   * Handle newly selected attachments
   */
  const handleNewAttachments = (files) => {
    const lastId = attachments.length ? Math.max(...attachments.map((file) => file.id)) + 1 : 0;
    const newAttachments = [
      ...attachments,
      ...files.map((file, index) => ({
        file,
        id: lastId + index,
        oversize: file.size > attachmentsConfig.data.maxSize,
      })),
    ];
    setAttachments(newAttachments);
    setValidAttachmentsCount(newAttachments.filter((att) => !att.oversize).length);
  };

  /**
   * Removes selected attachment from the list
   */
  const removeAttachment = (fileId, oversize) => {
    setAttachments(attachments.filter((file) => file.id !== fileId));
    if (!oversize) {
      setValidAttachmentsCount(validAttachmentsCount - 1);
    }
  };

  /**
   * Save new ticket data after submit
   * @param {Array} data
   */
  const onSubmit = async (data) => {
    setLeaveFormConfirmed(true);
    sendTicket({ ticket: data, attachments });
  };

  useEffect(() => {
    const setDefaultPriority = () => {
      // To prevent warning message from react for hook default
      // value for priority should be set after priorities state was set
      if (priorities.data !== undefined && getValues('priority') === '') {
        setValue('priority', 7);
      }
    };

    const setDefaultWorkType = () => {
      // To prevent warning message from react for hook default
      // value for work type should be set after priorities state was set
      if (
        workTypes.data !== undefined &&
        getValues('workType') === '' &&
        Object.keys(workTypes.data).length === 1
      ) {
        setValue('workType', Object.keys(workTypes.data)[0]);
      }
    };

    const leaveServiceRequestForm = () => {
      if (leaveFormConfirmed && nextLocation?.pathname) {
        reset({});
        history.push(nextLocation.pathname);
      }
    };

    leaveServiceRequestForm();
    setDefaultPriority();
    setDefaultWorkType();
  }, [
    setValue,
    getValues,
    priorities,
    workTypes,
    leaveFormConfirmed,
    history,
    nextLocation,
    reset,
  ]);

  return (
    <div>
      <Prompt
        when={(isDirty && Object.keys(dirtyFields).length > 0) || validAttachmentsCount > 0}
        message={confirmFormLeaving}
      />
      {displayForm && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            className="srFormGrid"
          >
            <Grid item xs={12}>
              <Controller
                name="summary"
                control={control}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 100,
                }}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    label={t('summary')}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    error={errors.summary !== undefined}
                    helperText={errors.summary !== undefined ? t('summaryError') : t('summaryHelp')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="srFormFieldRequired"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="details"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    label={t('details')}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={6}
                    error={errors.details !== undefined}
                    helperText={errors.details !== undefined ? t('detailsError') : t('detailsHelp')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className="srPriority srFormFieldRequired">
                <InputLabel color="secondary" shrink>
                  {t('priority')}
                </InputLabel>
                <Controller
                  name="priority"
                  control={control}
                  value={priorities.data}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      label={t('priority')}
                      color="secondary"
                      disabled={priorities.data === undefined}
                      className={priorities.data === undefined ? 'srFieldLoadingIcon' : ''}
                    >
                      {priorities.data !== undefined &&
                        Object.entries(priorities.data).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className="srFormWorkType srFormFieldRequired">
                <InputLabel
                  color="secondary"
                  shrink
                  className={errors.workType !== undefined ? 'Mui-error' : ''}
                >
                  {t('workType')}
                </InputLabel>
                <Controller
                  name="workType"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      label={t('workType')}
                      color="secondary"
                      disabled={workTypes.data === undefined}
                      className={workTypes.data === undefined ? 'srFieldLoadingIcon' : ''}
                      error={errors.workType !== undefined}
                    >
                      {workTypes.data !== undefined &&
                        Object.entries(workTypes.data).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            {problemCodes.data && Object.keys(problemCodes.data).length > 0 && (
              <Grid item xs={12}>
                <FormControl variant="outlined" className="srFormProblemCode">
                  <InputLabel
                    color="secondary"
                    shrink
                    className={errors.problemCode !== undefined ? 'Mui-error' : ''}
                  >
                    {t('problemCode')}
                  </InputLabel>
                  <Controller
                    name="problemCode"
                    control={control}
                    render={({ field }) => (
                      <Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        label={t('problemCode')}
                        color="secondary"
                        className={problemCodes.data === undefined ? 'srFieldLoadingIcon' : ''}
                        error={errors.problemCode !== undefined}
                      >
                        <MenuItem value="">
                          <em>{t('none')}</em>
                        </MenuItem>
                        {problemCodes.data !== undefined &&
                          Object.entries(problemCodes.data).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="customerRef"
                control={control}
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 50,
                }}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    label={t('customerRef')}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    error={errors.customerRef !== undefined}
                    helperText={
                      errors.customerRef !== undefined
                        ? t('customerRefError')
                        : t('customerRefHelp')
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="srFormFieldRequired"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {attachmentsConfig.data && attachments && attachments.length > 0 && (
                <Box>
                  <InputLabel color="secondary" shrink className="srFormAttachmentLabel">
                    {t('attachments')}
                  </InputLabel>
                  {attachments.map((file) => (
                    <Chip
                      key={file.id}
                      icon={<AttachFileOutlinedIcon color="secondary" fontSize="small" />}
                      label={`${
                        file.file.name.length > MAX_FILE_NAME_LENGTH
                          ? `${file.file.name.substring(0, MAX_FILE_NAME_LENGTH)}...`
                          : file.file.name
                      } (${getFileSize(file.file.size)})`}
                      deleteIcon={<DeleteIcon />}
                      variant="outlined"
                      onDelete={() => {
                        removeAttachment(file.id, file.oversize);
                      }}
                      className={`srFormAttachmentChip ${
                        file.oversize ? 'srFormTooBigAttachment' : ''
                      }`}
                    />
                  ))}
                  {attachments.length !== validAttachmentsCount && (
                    <FormHelperText className="Mui-error" variant="outlined">
                      {t('tooBigAttachmentExists', {
                        limit: getFileSize(attachmentsConfig.data.maxSize),
                      })}
                    </FormHelperText>
                  )}
                  {validAttachmentsCount > attachmentsConfig.data.maxCount && (
                    <FormHelperText className="Mui-error" variant="outlined">
                      {t('tooManyAttachments', { limit: attachmentsConfig.data.maxCount })}
                    </FormHelperText>
                  )}
                </Box>
              )}
              {attachmentsConfig.data && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  type="button"
                  disabled={validAttachmentsCount >= attachmentsConfig.data.maxCount}
                  className="serviceRequestSaveButton"
                  startIcon={<PhotoCamera />}
                  onClick={() => inputFiles.current.click()}
                >
                  {t('selectAttachments')}
                  <input
                    ref={inputFiles}
                    onChange={(event) => handleNewAttachments([...event.target.files])}
                    hidden
                    accept={attachmentsConfig.data.fileTypes.map((ft) => `.${ft}`).join(',')}
                    multiple
                    type="file"
                  />
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                type="submit"
                disabled={
                  priorities.data === undefined ||
                  workTypes.data === undefined ||
                  problemCodes.data === undefined ||
                  attachmentsConfig.data === undefined ||
                  validAttachmentsCount > attachmentsConfig.data.maxCount
                }
                className="serviceRequestSaveButton"
                startIcon={<BuildIcon />}
              >
                {t('send')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      <Dialog open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <DialogContent className="dialogLeaveTicketContent">
          <HelpOutlineIcon color="secondary" />
          <DialogContentText>{t('confirmationLeavingSrForm')}</DialogContentText>
        </DialogContent>
        <DialogActions className="dialogLeaveTicketActions">
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setLeaveFormConfirmed(true)}
            autoFocus
            className="dialogLeaveTicketBtMarked"
          >
            {t('ok')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setDialogVisible(false)}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ServiceRequestForm.propTypes = {
  sendTicket: PropTypes.func.isRequired,
  displayForm: PropTypes.bool.isRequired,
  attachmentsConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  priorities: PropTypes.objectOf(PropTypes.any).isRequired,
  workTypes: PropTypes.objectOf(PropTypes.any).isRequired,
  problemCodes: PropTypes.objectOf(PropTypes.any),
};

ServiceRequestForm.defaultProps = {
  problemCodes: [],
};

export default ServiceRequestForm;
