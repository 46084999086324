import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Location from '../location/Location';
import Asset from '../asset/Asset';
import ButtonSection from '../ButtonSection';
import BackendClient from '../../services/BackendClient';
import Message from '../Message';
import Loading from '../Loading';

/**
 * Asset and Location fetch component
 * @author Bartosz Marchewka, Trivalo
 */
const AssetLoc = ({
  setUid,
  setAppScope,
  setContactsCount,
  contactsCount,
  setOngoingWorkCount,
  ongoingWorkCount,
  setHistoricalWorkCount,
  historicalWorkCount,
}) => {
  const [assetLoc, setAssetLoc] = useState({ data: undefined, error: undefined });
  const [loading, setLoading] = useState(true);

  const { uid, appScope } = useParams();

  useEffect(() => {
    // Fetch information about the location from backend
    const fetchBusinessData = async () => {
      const assetLocData =
        appScope === 'asset'
          ? await BackendClient.getAsset(uid)
          : await BackendClient.getLocation(uid);
      setAssetLoc(assetLocData);
      setLoading(false);
    };

    // Check if contacts are assign to location
    // Because of cache configuration in backend side, frontend
    // can ask for data in location view as weel as in PersonList component
    const fetchPesonsData = async () => {
      const contactsData = await BackendClient.getContacts(uid);
      // If error occurs consider it also as correct answer with some data.
      // The user will go to contact view and see information about error or empty contact list.
      setContactsCount(contactsData.data?.length || (contactsData.error !== undefined ? -1 : 0));
    };

    // Check if ongoing works are assign to location
    // Because of cache configuration in backend side, frontend
    // can ask for data in location view as weel as in WorkList component
    const fetchOngoingWorkData = async () => {
      const worksData = await BackendClient.getOngoingWork(uid);
      // If error occurs consider it also as correct answer with some data.
      // The user will go to work view and see information about error or empty work list.
      setOngoingWorkCount(worksData.data?.length || (worksData.error !== undefined ? -1 : 0));
    };

    // Check if historical works are assign to location
    // Because of cache configuration in backend side, frontend
    // can ask for data in location view as weel as in WorkList component
    const fetchHistoricalWorkData = async () => {
      const worksData = await BackendClient.getHistoricalWork(uid);
      // If error occurs consider it also as correct answer with some data.
      // The user will go to work view and see information about error or empty work list.
      setHistoricalWorkCount(worksData.data?.length || (worksData.error !== undefined ? -1 : 0));
    };

    /**
     * Set uid value that comes from weburl parameter
     * into the uid state that is defined in App.jsx
     */
    setUid(uid);
    setAppScope(appScope);
    fetchBusinessData();
    fetchPesonsData();
    fetchOngoingWorkData();
    fetchHistoricalWorkData();
  }, [
    setContactsCount,
    setOngoingWorkCount,
    setHistoricalWorkCount,
    setUid,
    uid,
    setAppScope,
    appScope,
  ]);

  return (
    <div>
      {loading && <Loading />}
      {assetLoc.data !== undefined && (
        <div>
          {appScope === 'location' && <Location location={assetLoc.data} />}
          {appScope === 'asset' && <Asset asset={assetLoc.data} />}
          <ButtonSection
            contactsCount={contactsCount}
            ongoingWorkCount={ongoingWorkCount}
            historicalWorkCount={historicalWorkCount}
          />
        </div>
      )}
      {assetLoc.error !== undefined && <Message type="error" messageKey={assetLoc.error} />}
    </div>
  );
};

AssetLoc.propTypes = {
  setUid: PropTypes.func.isRequired,
  setAppScope: PropTypes.func.isRequired,
  setContactsCount: PropTypes.func.isRequired,
  contactsCount: PropTypes.number,
  setOngoingWorkCount: PropTypes.func.isRequired,
  ongoingWorkCount: PropTypes.number,
  setHistoricalWorkCount: PropTypes.func.isRequired,
  historicalWorkCount: PropTypes.number,
};

AssetLoc.defaultProps = {
  contactsCount: undefined,
  ongoingWorkCount: undefined,
  historicalWorkCount: undefined,
};

export default AssetLoc;
