import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import logo from '../images/quant_logo.png';

/**
 * Footer component
 * @author Bartosz Marchewka, Trivalo
 */
const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.primary.main,
    fontSize: '0.12in',
    color: 'white',
    paddingBottom: '0px',
  },
}));

const Footer = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <div className="footerData">
        <p className="footerPrivacy">
          © {new Date().getFullYear()} {t('companyName')}&nbsp;
          <Link color="secondary" href="https://www.quantservice.com/privacy/" target="_blank">
            {t('privacy')}
          </Link>
        </p>
        <Typography className="footerLogo" color="secondary">
          <img src={logo} className="logo" alt="Quant Logo" />
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
