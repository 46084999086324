import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * Error message component
 * @author Bartosz Marchewka, Trivalo
 */
const Message = ({ type, messageKey, messageValues, btShow, btName, btDelegateAction }) => {
  const { t } = useTranslation();

  // Take home path from route
  const homePath = useRouteMatch({
    path: '/:appScope/:uid',
    strict: true,
    sensitive: true,
  });

  const getMessageIcon = (messageType) => {
    switch (messageType) {
      case 'info':
        return <CheckCircleOutlineIcon color="primary" />;
      case 'warning':
        return <HelpOutlineIcon color="secondary" />;
      case 'error':
        return <ErrorOutlineIcon color="error" />;
      default:
        return <CheckCircleOutlineIcon color="primary" />;
    }
  };

  return (
    <div className="messageSection">
      {getMessageIcon(type)}
      <div>{t(messageKey, messageValues)}</div>
      {btShow &&
        (btDelegateAction === undefined ? (
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to={homePath?.url}
          >
            {t(btName)}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => btDelegateAction()}
          >
            {t(btName)}
          </Button>
        ))}
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'error']),
  messageKey: PropTypes.string,
  messageValues: PropTypes.objectOf(PropTypes.any),
  btShow: PropTypes.bool,
  btName: PropTypes.string,
  btDelegateAction: PropTypes.func,
};

Message.defaultProps = {
  btShow: false,
  btName: 'ok',
  type: 'info',
  messageKey: '',
  messageValues: {},
  btDelegateAction: undefined,
};

export default Message;
