import React from 'react';
import { CircularProgress } from '@material-ui/core';

/**
 * Loading component
 * @author Bartosz Marchewka, Trivalo
 */
const Loading = () => (
  <div className="loading">
    <CircularProgress className="buttonSection" color="primary" />
  </div>
);

export default Loading;
