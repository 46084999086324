import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

/**
 * Data Section component
 * Display object information
 * @author Bartosz Marchewka, Trivalo
 */
const DataSection = ({ label, id, desc }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="dataMainSection">
        <Typography color="secondary" variant="subtitle1">
          {t(label)}:
        </Typography>
        <Typography color="textPrimary" variant="subtitle1">
          <b>{id}</b>
        </Typography>
      </div>
      {desc !== undefined && (
        <div className="dataSubSection">
          <Typography color="secondary" variant="subtitle1">
            {t('description')}:<div className="dataSubSectionDesc">{desc}</div>
          </Typography>
        </div>
      )}
    </div>
  );
};

DataSection.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default DataSection;
