import { createTheme } from '@material-ui/core/styles';
import Graphik from './fonts/Graphik-Regular.woff2';
import Product from './fonts/Produkt-Regular.woff2';

const graphitCustomFont = {
  fontFamily: 'Graphik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Graphik'),
    url(${Graphik}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const produktCustomFont = {
  fontFamily: 'Product',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Product'),
    url(${Product}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createTheme({
  typography: {
    fontFamily: 'Graphik, Tahoma, Product',
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: '#00C46B',
      main: '#00C46B',
      dark: '#00C46B',
      contrastText: '#fff',
    },
    secondary: {
      light: '#002C5F',
      main: '#002C5F',
      dark: '#002C5F',
      contrastText: '#fff',
    },
    error: {
      light: '#F53F5B',
      main: '#F53F5B',
      dark: '#F53F5B',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#000',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [graphitCustomFont, produktCustomFont],
      },
    },
    MuiIconButton: {
      root: {
        color: 'white',
      },
    },
    MuiTypography: {
      root: {
        color: 'black',
        textAlign: 'left',
      },
    },
    MuiCircularProgress: {
      root: {
        color: '#00C46B !important',
      },
    },
  },
});
export default theme;
