import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * Work Details component - child component for Work.jsx
 * It's a section below work order description
 * @author Bartosz Marchewka, Trivalo
 */
const WorkDetails = ({ label, value, desc }) => {
  const { t } = useTranslation();
  return (
    <div className="workDetailsRow">
      <Typography variant="body2" color="secondary" component="p">
        {t(label)}:
      </Typography>
      <Typography variant="body2" color="textPrimary" component="p">
        {value}
        {desc ? ` - ${desc}` : null}
      </Typography>
    </div>
  );
};

WorkDetails.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  desc: PropTypes.string,
};

WorkDetails.defaultProps = {
  value: '',
  desc: '',
};

export default WorkDetails;
