import React from 'react';
import PropTypes from 'prop-types';
import DataSection from '../DataSection';

/**
 * Asset component - child component for AssetLoc.
 * @author Bartosz Marchewka, Trivalo
 */
const AssetData = ({ asset }) =>
  asset !== undefined ? (
    <div className="asset">
      <DataSection label="assetId" id={asset.asset} desc={asset.assetDesc} />
      <DataSection label="location" id={asset.location} desc={asset.locationDesc} />
    </div>
  ) : (
    <div />
  );

AssetData.propTypes = {
  asset: PropTypes.shape({
    asset: PropTypes.string.isRequired,
    assetDesc: PropTypes.string.isRequired,
    siteId: PropTypes.string.isRequired,
    location: PropTypes.string,
    locationDesc: PropTypes.string,
  }).isRequired,
};

export default AssetData;
