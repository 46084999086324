import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkDetails from './WorkDetails';

/**
 * Work component - child component for WorkList.
 * Display information about the work
 * @author Bartosz Marchewka, Trivalo
 */
const Work = ({ work }) => {
  const [showWorkDetails, setShowWorkDetails] = useState(false);
  return (
    <div>
      <Accordion
        onChange={(event, expanded) => {
          setShowWorkDetails(expanded);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />} id="workSummary">
          <Typography color="textSecondary">
            <b>{work.woNum}</b>
            {work.priority === 9 && <ErrorOutlineIcon className="workPriorityIcon" />}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={`${showWorkDetails ? '' : 'workDescTrim'}`}
          >
            {work.description}
          </Typography>
        </AccordionSummary>
        <AccordionDetails id="workDetailsSection">
          <WorkDetails label="workType" value={work.workTypeDesc} />
          <WorkDetails label="status" value={work.status} desc={work.statusDesc} />
          <WorkDetails label="priority" value={work.priority} desc={work.priorityDesc} />
          {work.history ? (
            <WorkDetails
              label="actualFinish"
              value={work.actualFinish ? new Date(work.actualFinish).toLocaleString() : null}
            />
          ) : (
            <WorkDetails
              label="scheduleStart"
              value={work.scheduledStart ? new Date(work.scheduledStart).toLocaleString() : null}
            />
          )}
          <WorkDetails
            label="reportDate"
            value={work.reportDate ? new Date(work.reportDate).toLocaleString() : null}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

Work.propTypes = {
  work: PropTypes.shape({
    woNum: PropTypes.string.isRequired,
    siteId: PropTypes.string.isRequired,
    description: PropTypes.string,
    workType: PropTypes.string,
    workTypeDesc: PropTypes.string,
    status: PropTypes.string,
    statusDesc: PropTypes.string,
    reportDate: PropTypes.string,
    scheduledStart: PropTypes.string,
    actualFinish: PropTypes.string,
    priority: PropTypes.number,
    priorityDesc: PropTypes.string,
    history: PropTypes.bool,
  }).isRequired,
};

export default Work;
