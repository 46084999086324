import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import BuildIcon from '@material-ui/icons/Build';
import HistoryIcon from '@material-ui/icons/History';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { useTranslation } from 'react-i18next';
import ItemCountIcon from './ItemCountIcon';

/**
 * Header with menu component
 * @author Bartosz Marchewka, Trivalo
 */
const Header = ({ uid, appScope, contactsCount, ongoingWorkCount, historicalWorkCount }) => {
  const { t } = useTranslation();
  const [isSubPage, setIsSubPage] = useState(false);
  const [isServiceRequestPage, setIsServiceRequestPage] = useState(false);
  const [isContactPage, setIsContactPage] = useState(false);
  const [isOngoingWorkPage, setIsOngoingWorkPage] = useState(false);
  const [isHistoricalWorkPage, setIsHistoricalWorkPage] = useState(false);
  const [isAboutPage, setIsAboutPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show history back icon only if the users is in sub pages
    setIsSubPage(
      location.pathname.match(/^\/(asset|location)\/.*\/.*/) != null ||
        location.pathname.match(/^\/about/) != null
    );
    // Set on which sub page is the user
    // Menu burger will be dynamically build based on these results
    setIsServiceRequestPage(location.pathname.match(/^\/(asset|location)\/.*\/sr/) != null);
    setIsContactPage(location.pathname.match(/^\/(asset|location)\/.*\/contact/) != null);
    setIsOngoingWorkPage(location.pathname.match(/^\/(asset|location)\/.*\/work\/ongoing/) != null);
    setIsHistoricalWorkPage(
      location.pathname.match(/^\/(asset|location)\/.*\/work\/historical/) != null
    );
    setIsAboutPage(location.pathname.match(/^\/about/) != null);
    // Hide about page - out of the PoC scope
    setIsAboutPage(true);
  }, [location]);

  const [anchorEl, open] = React.useState(null);

  const menuClick = (event) => {
    open(event.currentTarget);
  };

  const menuClose = () => {
    open(null);
  };

  const menuSelected = () => {
    open(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <div className="headerHistoryBack">
          {isSubPage && (
            <IconButton
              color="default"
              aria-label="back"
              component={Link}
              to={`/${appScope}/${uid}`}
            >
              <ArrowBackIosIcon className="historyBackIcon" />
            </IconButton>
          )}
        </div>
        <Typography className="headerLogo" color="inherit">
          {t('appName')}&trade;
        </Typography>
        <IconButton edge="start" aria-label="menu" onClick={menuClick}>
          <MenuIcon />
        </IconButton>
        {uid !== undefined ? (
          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={menuClose}
          >
            {isSubPage && (
              <MenuItem onClick={menuSelected} component={Link} to={`/${appScope}/${uid}`}>
                <HomeIcon color="secondary" />
                <Typography className="menu-label" color="secondary">
                  {t('home')}
                </Typography>
              </MenuItem>
            )}
            {!isServiceRequestPage && (
              <MenuItem onClick={menuSelected} component={Link} to={`/${appScope}/${uid}/sr`}>
                <BuildIcon color="secondary" />
                <Typography className="menu-label" color="secondary">
                  {t('menuServiceRequest')}
                </Typography>
              </MenuItem>
            )}
            {!isOngoingWorkPage && (
              <MenuItem
                onClick={menuSelected}
                component={Link}
                to={`/${appScope}/${uid}/work/ongoing`}
                disabled={!ongoingWorkCount}
              >
                <ItemCountIcon count={ongoingWorkCount} icon={<BusinessCenterIcon />} />
                <Typography className="menu-label" color="secondary">
                  {t('ongoingWork')}
                </Typography>
              </MenuItem>
            )}
            {!isHistoricalWorkPage && (
              <MenuItem
                onClick={menuSelected}
                component={Link}
                to={`/${appScope}/${uid}/work/historical`}
                disabled={!historicalWorkCount}
              >
                <HistoryIcon color="secondary" />
                <Typography className="menu-label" color="secondary">
                  {t('historicalWork')}
                </Typography>
              </MenuItem>
            )}
            {!isContactPage && (
              <MenuItem
                onClick={menuSelected}
                component={Link}
                to={`/${appScope}/${uid}/contact`}
                disabled={!contactsCount}
              >
                <PeopleIcon color="secondary" />
                <Typography className="menu-label" color="secondary">
                  {t('contactPersons')}
                </Typography>
              </MenuItem>
            )}
            {!isAboutPage && (
              <MenuItem onClick={menuSelected} component={Link} to="/about">
                <InfoIcon color="secondary" />
                <Typography className="menu-label" color="secondary">
                  {t('menuAbout')}
                </Typography>
              </MenuItem>
            )}
          </Menu>
        ) : (
          ''
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  uid: PropTypes.string,
  appScope: PropTypes.string,
  contactsCount: PropTypes.number,
  ongoingWorkCount: PropTypes.number,
  historicalWorkCount: PropTypes.number,
};

Header.defaultProps = {
  uid: undefined,
  appScope: undefined,
  contactsCount: undefined,
  ongoingWorkCount: undefined,
  historicalWorkCount: undefined,
};

export default Header;
