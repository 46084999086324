import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ServiceRequestForm from './ServiceRequestForm';
import Loading from '../Loading';
import Message from '../Message';
import BackendClient from '../../services/BackendClient';

/**
 * Service request component
 * @author Bartosz Marchewka, Trivalo
 */
const ServiceRequest = ({ setUid, setAppScope }) => {
  const { uid, appScope } = useParams();
  // ticket - result from backend when service request is created
  const [ticket, setTicket] = useState({ data: undefined, error: undefined });
  // ticketFormData - data from form used for retry functionalities
  const [ticketFormData, setTicketFormData] = useState();
  const [attachmentsConfig, setAttachmentsConfig] = useState({ data: undefined, error: undefined });
  const [priorities, setPriorities] = useState({ data: undefined, error: undefined });
  const [workTypes, setWorkTypes] = useState({ data: undefined, error: undefined });
  const [problemCodes, setProblemCodes] = useState({ data: undefined, error: undefined });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    /**
     * Set uid value that comes from weburl parameter
     * into the uid state that is defined in App.jsx
     */
    setUid(uid);

    setAppScope(appScope);

    // Load attachments config
    const fetchAttachmentsConfig = async () => {
      const config = await BackendClient.getAttachmentsConfig(uid);
      setAttachmentsConfig(config);
    };

    // Take priorities list definition from backend
    const fetchPriorities = async () => {
      const prioritiesData = await BackendClient.getTicketPriorities(uid);
      setPriorities(prioritiesData);
    };

    // Take work types list definition from backend
    const fetchWorkTypes = async () => {
      const workTypesData = await BackendClient.getTicketWorkTypes(uid);
      setWorkTypes(workTypesData);
    };

    // Fetch problem codes list definition from backend
    const fetchProblemCodes = async () => {
      const problemCodesData = await BackendClient.getTicketProblemCodes(uid);
      setProblemCodes(problemCodesData);
    };

    fetchAttachmentsConfig();
    fetchPriorities();
    fetchWorkTypes();
    fetchProblemCodes();
  }, [setUid, uid, setAppScope, appScope]);

  // Reload priorities and/or work type list
  // after error flow
  const reloadTicketForm = async () => {
    setLoading(true);
    if (attachmentsConfig.error !== undefined) {
      const configData = await BackendClient.getAttachmentsConfig(uid);
      setAttachmentsConfig(configData);
    }
    if (workTypes.error !== undefined) {
      const workTypesData = await BackendClient.getTicketWorkTypes(uid);
      setWorkTypes(workTypesData);
    }
    if (priorities.error !== undefined) {
      const prioritiesData = await BackendClient.getTicketPriorities(uid);
      setPriorities(prioritiesData);
    }
    if (problemCodes.error !== undefined) {
      const problemCodesData = await BackendClient.getTicketProblemCodes(uid);
      setProblemCodes(problemCodesData);
    }
    setLoading(false);
  };

  // Send data from ticket form to backend.
  const sendTicket = async (data) => {
    // Keep form data in state in case of error flow
    // and needs of retry functionalities
    setTicketFormData(data);
    setLoading(true);
    const ticketData = await BackendClient.saveTicket(uid, data);
    setTicket(ticketData);
    setLoading(false);
  };

  // Retry functionalities after error flow
  const retryTicketForm = () => {
    setTicket({ error: undefined });
    sendTicket(ticketFormData);
  };

  return (
    <div className="serviceRequest">
      {loading && <Loading />}
      {ticket.data !== undefined && (
        <Message
          type="info"
          btName="ok"
          messageKey="ticketCreated"
          messageValues={{ ticketId: ticket.data.ticketId }}
          btShow
        />
      )}
      <ServiceRequestForm
        attachmentsConfig={attachmentsConfig}
        priorities={priorities}
        workTypes={workTypes}
        problemCodes={problemCodes}
        sendTicket={sendTicket}
        displayForm={
          !loading &&
          ticket.data === undefined &&
          ticket.error === undefined &&
          attachmentsConfig.error === undefined &&
          priorities.error === undefined &&
          workTypes.error === undefined &&
          problemCodes.error === undefined
        }
      />
      {ticket.error !== undefined && !loading && (
        <Message
          type="error"
          messageKey={ticket.error}
          btName="retry"
          btDelegateAction={retryTicketForm}
          btShow
        />
      )}
      {(attachmentsConfig.error !== undefined ||
        priorities.error !== undefined ||
        workTypes.error !== undefined ||
        problemCodes.error !== undefined) &&
        !loading && (
          <Message
            type="error"
            messageKey="errorGeneral"
            btName="reload"
            btDelegateAction={reloadTicketForm}
            btShow
          />
        )}
    </div>
  );
};

ServiceRequest.propTypes = {
  setUid: PropTypes.func.isRequired,
  setAppScope: PropTypes.func.isRequired,
};
export default ServiceRequest;
