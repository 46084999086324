import React from 'react';
import PropTypes from 'prop-types';

import DataSection from '../DataSection';

/**
 * Location component - entry point to take
 * location data from backend
 * @author Bartosz Marchewka, Trivalo
 */
const Location = ({ location }) => (
  <div>
    {location !== undefined && (
      <div>
        <div className="location">
          <DataSection label="location" id={location.location} desc={location.locationDesc} />
        </div>
      </div>
    )}
  </div>
);

Location.propTypes = {
  location: PropTypes.shape({
    location: PropTypes.string,
    locationDesc: PropTypes.string,
  }).isRequired,
};

export default Location;
