import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import Message from '../Message';
import Work from './Work';

/**
 * Ongoing work component - entry point to take
 * work list data from backend
 * @author Bartosz Marchewka, Trivalo
 */
const WorkList = ({ fetchWorkData }) => {
  const [loading, setLoading] = useState(true);
  const [works, setWorks] = useState({ data: undefined, error: undefined });

  useEffect(() => {
    const doFetchWorkData = async () => {
      const worksData = await fetchWorkData();
      setWorks(worksData);
      setLoading(false);
    };
    doFetchWorkData();
  }, [fetchWorkData]);

  return (
    <div className="works">
      {loading && <Loading />}
      {works.error !== undefined && <Message type="error" messageKey={works.error} />}
      {works.data?.length === 0 && <Message type="warning" messageKey="emptyWorkList" />}
      {works.data?.map((work) => (
        <Work key={work.woNum} work={work} />
      ))}
    </div>
  );
};

WorkList.propTypes = {
  fetchWorkData: PropTypes.func.isRequired,
};
export default WorkList;
