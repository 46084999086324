import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Person from './Person';
import BackendClient from '../../services/BackendClient';
import Message from '../Message';
import Loading from '../Loading';

/**
 * Contact list component - entry point to take
 * contact data from backend
 * @author Bartosz Marchewka, Trivalo
 */
const PersonList = ({ setUid, setAppScope }) => {
  const [contacts, setContacts] = useState({ data: undefined, error: undefined });
  const [loading, setLoading] = useState(true);

  const { uid, appScope } = useParams();

  useEffect(() => {
    const fetchPesonsData = async () => {
      const contactsData = await BackendClient.getContacts(uid);
      setContacts(contactsData);
      setLoading(false);
    };
    /**
     * Set uid value that comes from weburl parameter
     * into the uid state that is defined in App.jsx
     */
    setUid(uid);
    setAppScope(appScope);
    fetchPesonsData();
  }, [setUid, uid, setAppScope, appScope]);

  return (
    <div className="contactPersons">
      {loading && <Loading />}
      {contacts.error !== undefined && <Message type="error" messageKey={contacts.error} />}
      {contacts.data?.length === 0 && <Message type="warning" messageKey="emptyContactList" />}
      {contacts.data?.map((person) => (
        <Person key={person.personId} person={person} />
      ))}
    </div>
  );
};

PersonList.propTypes = {
  setUid: PropTypes.func.isRequired,
  setAppScope: PropTypes.func.isRequired,
};

export default PersonList;
